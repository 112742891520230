.programs {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 0 2rem;

  &-header {
    display: flex;
    gap: 5rem;
    justify-content: center;
    font-weight: bold;
    font-size: 3rem;
    color: white;
    text-transform: uppercase;
    font-style: italic;
  }

  &-cards {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: space-evenly;
  }
  .program {
    background-color: gray;
    padding: 2rem;
    gap: 1rem;
    color: white;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &:hover {
      background: var(--planCard);
    }

    & > :first-child {
      width: 2rem;
      height: 2rem;
      fill: white;
    }
    &-heading {
      font-weight: bold;
      font-size: 1rem;
    }
    &-details {
      font-size: 0.9rem;
      line-height: 1.6rem;
    }
  }
}

@media screen and (max-width: 48rem) {
  .programs-header {
    flex-direction: column;
    gap: 1rem;
    font-size: 2.5rem;
    align-items: center;
    justify-content: center;
  }

  .programs-cards {
    flex-direction: column;
  }
}
