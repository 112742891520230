.header {
  display: flex;
  justify-content: space-between;
}
.header .logo {
  width: 10rem;
  height: 3rem;
}
.header-menu {
  list-style: none;
  display: flex;
  gap: 2rem;
  color: white;
  font-weight: bold;
  padding-right: 2rem;
}
.header-menu > li:hover {
  color: var(--orange);
  cursor: pointer;
  transition: all ease-in-out 0.3s;
}
@media screen and (max-width: 48rem) {
  .header-menu {
    position: fixed;
    right: 2rem;
    flex-direction: column;
    z-index: 100;
    background: var(--appColor);
    padding: 2rem;
    display: none;
  }
}

.bars {
  background: var(--appColor);
  padding: 0.5rem;
  border-radius: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  position: fixed;
  top: 3rem;
  right: 3rem;
  z-index: 101;
}/*# sourceMappingURL=Header.css.map */