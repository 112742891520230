.testimonials {
  display: flex;
  gap: 1rem;
  padding: 0 2rem 3rem;
}

.left-t {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  gap: 2rem;
}
.testimonials-header {
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  color: white;
}

.testimonials-header > h1 {
  color: var(--orange);
  font-weight: bold;
  font-size: 1.5rem;
}

.testimonials-header > span {
  font-size: 3rem;
  font-weight: bold;
}

.reviews {
  color: white;
  text-align: justify;
}

.reviews > p {
  letter-spacing: 2px;
  line-height: 2rem;
}

.reviews > :nth-child(2) {
  color: var(--orange);
}

.right-t {
  display: flex;
  flex: 1 1;
  position: relative;
}

.right-t > img {
  object-fit: cover;
  width: 17rem;
  height: 20rem;
  position: absolute;
  top: 2rem;
  right: 8rem;
}

.right-t > :nth-child(1) {
  position: absolute;
  right: 9rem;
  top: 0.9rem;
  width: 17rem;
  height: 20rem;
  border: 3px solid var(--orange);
  background-color: transparent;
}

.right-t > :nth-child(2) {
  position: absolute;
  right: 7rem;
  top: 4rem;
  width: 17rem;
  height: 19rem;
  background: var(--planCard);
}

.arrows {
  position: absolute;
  display: flex;
  gap: 1rem;
  bottom: 1rem;
  left: 3rem;
}

.arrows > img {
  width: 1.5rem;
  cursor: pointer;
}

@media screen and (max-width: 48rem) {
  .testimonials {
    flex-direction: column;
  }
  .right-t {
    align-items: center;
    justify-content: center;
  }
  .right-t > div {
    flex-direction: column;
    display: none;
  }

  .right-t > img {
    top: 0;
    right: 0;
    position: relative;
    align-self: center;
  }
  .right-t > :last-child {
    display: block;
    bottom: -2rem;
    left: 9rem;
  }
}
