.hero {
  display: flex;
  justify-content: space-between;
}
.hero-blur {
  width: 22rem;
  height: 30rem;
  left: 0;
}

.hero .left-side {
  padding: 2rem;
  padding-top: 1.3rem;
  flex: 3;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.hero .left-side .the-best-ad {
  margin-top: 4rem;
  background-color: #343d42;
  border-radius: 4rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 1.3rem 0.8rem;
  text-transform: uppercase;
  color: white;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}
.hero .left-side .the-best-ad .slider {
  position: absolute;
  background-color: var(--orange);
  width: 5.4rem;
  height: 80%;
  left: 8px;
  border-radius: 3rem;
  z-index: 1;
}
.hero .left-side .the-best-ad > span {
  z-index: 2;
}
.hero-text {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  text-transform: uppercase;
  font-size: 4.5rem;
  font-weight: bold;
  color: white;
  text-overflow: inherit;
}
.hero-description {
  font-size: 1rem;
  font-weight: 200;
  text-transform: none;
  letter-spacing: 1px;
  width: 80%;
  color: white;
}

.figures {
  display: flex;
  gap: 2rem;
}
.figures > div {
  display: flex;
  flex-direction: column;
}
.figures > div > span:nth-of-type(1) {
  font-size: 2rem;
  color: white;
}
.figures > div > span:nth-of-type(2) {
  color: var(--gray);
  text-transform: uppercase;
}

.hero-buttons {
  display: flex;
  gap: 2rem;
  font-weight: normal;
}
.hero-buttons > :nth-child(1) {
  color: white;
  background: var(--orange);
  width: 8rem;
}
.hero-buttons > :nth-child(2) {
  width: 8rem;
  color: white;
  background-color: transparent;
  border: 2px solid var(--orange);
}

.right-side {
  flex: 1;
  position: relative;
  background-color: var(--orange);
  z-index: -1;
}
.right-side > .btn {
  position: absolute;
  right: 5rem;
  top: 6rem;
  color: black;
}
.right-side .hero_image {
  position: absolute;
  top: 10rem;
  right: 8rem;
  width: 23rem;
  z-index: 10;
}
.right-side .hero_image_back {
  position: absolute;
  top: 4rem;
  right: 20rem;
  width: 11rem;
}
.right-side .calories {
  color: white;
  display: flex;
  gap: 2rem;
  background: var(--caloryCard);
  border-radius: 0.5rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 2rem auto;
  padding: 1rem;
}

@media screen and (max-width: 48rem) {
  .hero {
    flex-direction: column;
  }

  .hero-blur {
    width: 14rem;
  }

  .hero .left-side .the-best-ad {
    margin-top: 0px;
    font-size: small;
    align-self: center;
    transform: scale(0.8);
  }

  .hero-text {
    font-size: 3rem;
    align-items: center;
    justify-content: center;
  }
  .hero-description {
    font-size: small;
  }

  .right-side {
    position: relative;
    background: none;
  }

  .right-side .hero_image {
    position: relative;
    width: 15rem;
    left: 8rem;
    top: 4rem;
    align-self: center;
  }
  .right-side .hero_image_back {
    align-self: center;
    width: 7rem;
    position: relative;
    top: 4rem;
    right: -21rem;
  }

  .right-side .calories {
    position: relative;
    top: 4rem;
  }
  .right-side > .btn {
    position: relative;
    top: 34rem;
    left: 13rem;
    z-index: 11;
  }
}
