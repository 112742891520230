.footer-container {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  justify-content: center;
  align-items: center;
  padding-bottom: 3rem;
}

.footer-container > hr {
  width: 100%;
  border: 1px solid lightgray;
  border-radius: 10%;
}

.social-media {
  display: flex;
  gap: 4rem;
}

.social-media > a > img {
  opacity: 0.8;
  width: 2rem;
  transition: all 300ms ease-in-out;
}

.social-media > a > img:hover {
  opacity: 1;
  transform: scale(1.05);
}

.footer-logo > img {
  opacity: 0.8;
  width: 11rem;
}

.footer-blur-1 {
  width: 26rem;
  height: 13rem;
  left: 15%;
  background: rgb(215, 44, 44);
  filter: blur(200px);
}
.footer-blur-2 {
  width: 26rem;
  height: 13rem;
  right: 15%;
  filter: blur(200px);
  background: rgb(215, 44, 44);
}

@media screen and (max-width: 48rem) {
  .footer-blur-1,
  .footer-blur-2 {
    width: 14rem;
    height: 13rem;
  }
}
